import { default as ai_45form_45buildernqH50dgWgZMeta } from "/var/www/opnform/client/pages/ai-form-builder.vue?macro=true";
import { default as editDf6Crtl69cMeta } from "/var/www/opnform/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexXr2tXAuWj2Meta } from "/var/www/opnform/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexhBeDM0ubWLMeta } from "/var/www/opnform/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexa9TaBlTJeqMeta } from "/var/www/opnform/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share36UHblmSTCMeta } from "/var/www/opnform/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsTdkQz8KkeMMeta } from "/var/www/opnform/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionslKZsPjG6CeMeta } from "/var/www/opnform/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showFvtGUGCBOkMeta } from "/var/www/opnform/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guest97ZGd8P0JwMeta } from "/var/www/opnform/client/pages/forms/create/guest.vue?macro=true";
import { default as indexWbQ3zxAUv8Meta } from "/var/www/opnform/client/pages/forms/create/index.vue?macro=true";
import { default as homefrXm8yHbWlMeta } from "/var/www/opnform/client/pages/home.vue?macro=true";
import { default as indexZQt6G8wT9gMeta } from "/var/www/opnform/client/pages/index.vue?macro=true";
import { default as logindJV9ktaiHBMeta } from "/var/www/opnform/client/pages/login.vue?macro=true";
import { default as callbackz4PzdcSYq9Meta } from "/var/www/opnform/client/pages/oauth/callback.vue?macro=true";
import { default as emailNiiwU3DPFdMeta } from "/var/www/opnform/client/pages/password/email.vue?macro=true";
import { default as _91token_936Ro0IXIOEHMeta } from "/var/www/opnform/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingdMlnZw87qKMeta } from "/var/www/opnform/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyN19eNLsj8uMeta } from "/var/www/opnform/client/pages/privacy-policy.vue?macro=true";
import { default as registerFS6W8mEl9eMeta } from "/var/www/opnform/client/pages/register.vue?macro=true";
import { default as access_45tokensZLX3mf1CriMeta } from "/var/www/opnform/client/pages/settings/access-tokens.vue?macro=true";
import { default as account2i21x3vq1xMeta } from "/var/www/opnform/client/pages/settings/account.vue?macro=true";
import { default as adminVL20cbPsYGMeta } from "/var/www/opnform/client/pages/settings/admin.vue?macro=true";
import { default as billingHYmJV1xYnpMeta } from "/var/www/opnform/client/pages/settings/billing.vue?macro=true";
import { default as connections3F0VwALzXaMeta } from "/var/www/opnform/client/pages/settings/connections.vue?macro=true";
import { default as indexE3fMtZPJ6sMeta } from "/var/www/opnform/client/pages/settings/index.vue?macro=true";
import { default as passwordeoGSeON5rmMeta } from "/var/www/opnform/client/pages/settings/password.vue?macro=true";
import { default as profilenvnyMHwR4XMeta } from "/var/www/opnform/client/pages/settings/profile.vue?macro=true";
import { default as workspace0ktZvPnstJMeta } from "/var/www/opnform/client/pages/settings/workspace.vue?macro=true";
import { default as settingsbu1uaAzT8cMeta } from "/var/www/opnform/client/pages/settings.vue?macro=true";
import { default as errors5FPEpNWPxMeta } from "/var/www/opnform/client/pages/subscriptions/error.vue?macro=true";
import { default as successVI4wJ83DzyMeta } from "/var/www/opnform/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93EAwqYfu4dMMeta } from "/var/www/opnform/client/pages/templates/[slug].vue?macro=true";
import { default as indexLbp4xwlQi5Meta } from "/var/www/opnform/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93EAXj7GOyn8Meta } from "/var/www/opnform/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesFfVttMtZzrMeta } from "/var/www/opnform/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93nFF2FgLddjMeta } from "/var/www/opnform/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionswvfRCJZJwAMeta } from "/var/www/opnform/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentials1VXJINq8u7Meta } from "/var/www/opnform/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45buildernqH50dgWgZMeta || {},
    component: () => import("/var/www/opnform/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editDf6Crtl69cMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showFvtGUGCBOkMeta?.name,
    path: "/forms/:slug()/show",
    meta: showFvtGUGCBOkMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexhBeDM0ubWLMeta?.redirect,
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexa9TaBlTJeqMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: share36UHblmSTCMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsTdkQz8KkeMMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionslKZsPjG6CeMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guest97ZGd8P0JwMeta || {},
    component: () => import("/var/www/opnform/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexWbQ3zxAUv8Meta || {},
    component: () => import("/var/www/opnform/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homefrXm8yHbWlMeta || {},
    component: () => import("/var/www/opnform/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/opnform/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logindJV9ktaiHBMeta || {},
    component: () => import("/var/www/opnform/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/var/www/opnform/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailNiiwU3DPFdMeta || {},
    component: () => import("/var/www/opnform/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_936Ro0IXIOEHMeta || {},
    component: () => import("/var/www/opnform/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingdMlnZw87qKMeta || {},
    component: () => import("/var/www/opnform/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyN19eNLsj8uMeta || {},
    component: () => import("/var/www/opnform/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerFS6W8mEl9eMeta || {},
    component: () => import("/var/www/opnform/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsbu1uaAzT8cMeta?.name,
    path: "/settings",
    meta: settingsbu1uaAzT8cMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/var/www/opnform/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: account2i21x3vq1xMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminVL20cbPsYGMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingHYmJV1xYnpMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connections3F0VwALzXaMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/var/www/opnform/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexE3fMtZPJ6sMeta?.redirect,
    component: () => import("/var/www/opnform/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordeoGSeON5rmMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilenvnyMHwR4XMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspace0ktZvPnstJMeta || {},
    component: () => import("/var/www/opnform/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errors5FPEpNWPxMeta || {},
    component: () => import("/var/www/opnform/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successVI4wJ83DzyMeta || {},
    component: () => import("/var/www/opnform/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/var/www/opnform/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/var/www/opnform/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/var/www/opnform/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesFfVttMtZzrMeta || {},
    component: () => import("/var/www/opnform/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/var/www/opnform/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionswvfRCJZJwAMeta || {},
    component: () => import("/var/www/opnform/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/var/www/opnform/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]